import React, { useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import loadable from "@loadable/component"
import * as styles from "./HomePageHeroSection.module.scss"
import MouseDownIcon from "../../assets/svgs/mouse-down.svg"

import HSFlag from "../HSFlag"

const SplitText = loadable(() => import("../Animated/SplitText"))
const FadeUpAndIn = loadable(() => import("../Animated/FadeUpAndIn"))

function HomePageHeroSection() {

  const servicesSection = useRef();
  const data = useStaticQuery(graphql`
    query HomePageHeroSectionQuery {
      contentfulSettings {
        homepageServiceBlocks {
          id
          title
          description
          serviceTypes {
            title
          }
        }
      }
    }
  `)

  const scrollToRef = ref => e => {
    e.preventDefault()
    if (!ref) return false
    ref.current.scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <>
      <div className={styles.heroContainer}>
        <h1 className={styles.heroTitleContainer}>
          <div className={styles.heroFlagContainer}>
            <FadeUpAndIn delay={1.4}>
              <HSFlag width={"59px"} />
            </FadeUpAndIn>
          </div>
          <SplitText delay={1.8}>
            Building and growing brands that keep people healthy.
          </SplitText>
          <div
            className={styles.mouseDownIcon}
            onClick={scrollToRef(servicesSection)}
            aria-label="Go To Next Section"
          >
            <FadeUpAndIn delay={2.1}>
              <MouseDownIcon />
            </FadeUpAndIn>
          </div>
        </h1>
      </div>

      <div ref={servicesSection} className={styles.servicesContainer}>
        {data.contentfulSettings.homepageServiceBlocks.map((block, index) => (
          <div className={styles.serviceContainer}>
            <div className={styles.serviceNumber}>0{index + 1}</div>
            <div className={styles.serviceTitle}>{block.title}</div>
            <div className={styles.servicesDescription}>
              {block.description}
            </div>
            {block.serviceTypes && block.serviceTypes.length > 0 && (
              <div className={styles.serviceTypesContainer}>
                {block.serviceTypes.map((serviceType, i) => (
                  <div className={styles.serviceType}>{serviceType.title}</div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default HomePageHeroSection
